export function numberFormatter(value, currency = null) {
  let $currencyArray = [
      {
        key: 'TRY',
        type: 'tr-TR',
      },
    ],
    $activeCurrency = $currencyArray.find(item => item.key === currency) || null;
  value = parseFloat(value).toFixed(2);
  if ($activeCurrency) {
    return new Intl.NumberFormat($activeCurrency.type, {
      style: 'currency',
      currency: $activeCurrency.key,
      minimumFractionDigits: 0,
    }).format(value);
  } else {
    return new Intl.NumberFormat('tr-TR').format(value);
  }
}
